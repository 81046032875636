<h2>{{ data.location }} - Holiday List</h2>
<table class="holiday-table">
  <thead>
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>Day</th>
      <th>Holiday</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let holiday of data.holidays">
      <td>{{ holiday.serial }}</td>
      <td>{{ holiday.date }}</td>
      <td>{{ holiday.day }}</td>
      <td>{{ holiday.holiday }}</td>
    </tr>
  </tbody>
</table>
