import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserperrmissionService } from './commommgmnt/userpermission/userperrmission.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SharedService {
    toasterImageURL :string;
    itemName:string
    public permisedModules: number = 0;
    permissedModSource = new BehaviorSubject(this.permisedModules);
    permisedModulelist = this.permissedModSource.asObservable();


    constructor(private http: HttpClient,
        private userperrmissionService: UserperrmissionService) {

    }
    private selectedTypeSource = new BehaviorSubject<string>(null);
  selectedType$ = this.selectedTypeSource.asObservable();//used shared service for brand component and customerdetails component

  setSelectedType(type: string) {
    this.selectedTypeSource.next(type);
  }

    // Observable string sources
    private emitChangeSource = new BehaviorSubject<number>(2);
    changeEmitted$ = this.emitChangeSource.asObservable();
    // Service message commands
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    private originalPcsSubject = new BehaviorSubject<number | undefined>(undefined); // Default value is undefined
    originalPcs$ = this.originalPcsSubject.asObservable();
  
    updateOriginalPcs(value: number): void {
      console.log('Updating originalPcs in service:', value); // Debug log
      this.originalPcsSubject.next(value); // Emit the updated value
    }
}