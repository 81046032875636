import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedService } from '@services/shared-service.service';

@Component({
  selector: 'app-editoriginalpieces',
  templateUrl: './editoriginalpieces.component.html',
  styleUrls: ['./editoriginalpieces.component.scss']
})
export class EditoriginalpiecesComponent implements OnInit {

  constructor(private sharedservirc: SharedService,
    public dialogRef: MatDialogRef<EditoriginalpiecesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { originalPcs: number }
  ) {}
  originalPcs: number;
  item: any;  // The item passed from the parent
  ngOnInit() {
    // Initialize the item in ngOnInit, after data has been injected
    this.item = { ...this.data };  
  }// No need for `this.data.item`, since `data` is the item itself  }
  onCancel(): void {
    this.dialogRef.close(); // Close without saving
  }

  onUpdate(): void {
    this.sharedservirc.updateOriginalPcs(this.originalPcs);
    this.dialogRef.close(this.item);  }

 
   
  

}
