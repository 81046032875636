<h1 mat-dialog-title class="dialog-title">Edit Original Pieces</h1>
<div mat-dialog-content class="dialog-content">
  <mat-form-field appearance="fill" class="full-width form-field">
    <mat-label>Original Pieces</mat-label>
    <input matInput type="number" [(ngModel)]="data.originalPcs" class="form-input" />
  </mat-form-field>
</div>
<div mat-dialog-actions class="dialog-actions">
  <button mat-button class="cancel-button" (click)="onCancel()">Cancel</button>

  <button mat-raised-button class="update-button" color="primary" (click)="onUpdate()">Update</button>
</div>
